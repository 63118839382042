export const CON = (id) => `CON_${id}`;
export const INS = (id) => `INS_${id}`;
export const ALT = (id) => `ALT_${id}`;
export const EXC = (id) => `EXC_${id}`;
export const CON_PUBLICO = (id) => `CON_PUBLICO_${id}`;
export const HISTORICO = `HISTORICO`;


export const CON_AUT_EMIT =  'CON_AUT_EMIT';
export const INS_AUT_EMIT = 'INS_AUT_EMIT';
export const ALT_AUT_EMIT = 'ALT_AUT_EMIT';
export const EXC_AUT_EMIT = 'EXC_AUT_EMIT';
export const CON_CONDICAO = 'CON_CONDICAO';
export const INS_CONDICAO = 'INS_CONDICAO';
export const ALT_CONDICAO = 'ALT_CONDICAO';
export const EXC_CONDICAO = 'EXC_CONDICAO';
export const CON_DEM_JUD = 'CON_DEM_JUD';
export const INS_DEM_JUD = 'INS_DEM_JUD';
export const ALT_DEM_JUD = 'ALT_DEM_JUD';
export const EXC_DEM_JUD = 'EXC_DEM_JUD';
export const INS_DEM_JUD_DOCUMENTO = 'INS_DEM_JUD_DOCUMENTO';
export const ALT_DEM_JUD_METADADOS = 'ALT_DEM_JUD_METADADOS';
export const CON_FINALIDADE = 'CON_FINALIDADE';
export const INS_FINALIDADE = 'INS_FINALIDADE';
export const ALT_FINALIDADE = 'ALT_FINALIDADE';
export const EXC_FINALIDADE = 'EXC_FINALIDADE';
export const CON_LIMITE = 'CON_LIMITE';
export const INS_LIMITE = 'INS_LIMITE';
export const ALT_LIMITE = 'ALT_LIMITE';
export const EXC_LIMITE = 'EXC_LIMITE';
export const CON_MODELO_QUADRO = 'CON_MODELO_QUADRO';
export const INS_MODELO_QUADRO = 'INS_MODELO_QUADRO';
export const ALT_MODELO_QUADRO = 'ALT_MODELO_QUADRO';
export const EXC_MODELO_QUADRO = 'EXC_MODELO_QUADRO';
export const CON_ITEM_VERIFICACAO = 'CON_ITEM_VERIFICACAO';
export const INS_ITEM_VERIFICACAO = 'INS_ITEM_VERIFICACAO';
export const ALT_ITEM_VERIFICACAO = 'ALT_ITEM_VERIFICACAO';
export const EXC_ITEM_VERIFICACAO = 'EXC_ITEM_VERIFICACAO';
export const CON_NORMATIVO = 'CON_NORMATIVO';
export const INS_NORMATIVO = 'INS_NORMATIVO';
export const ALT_NORMATIVO = 'ALT_NORMATIVO';
export const EXC_NORMATIVO = 'EXC_NORMATIVO';
export const INS_NORMATIVO_DOCUMENTO = 'INS_NORMATIVO_DOCUMENTO';
export const ALT_NORMATIVO_METADADOS = 'ALT_NORMATIVO_METADADOS';
export const CON_PARAM_CALCULO = 'CON_PARAM_CALCULO';
export const INS_PARAM_CALCULO = 'INS_PARAM_CALCULO';
export const ALT_PARAM_CALCULO = 'ALT_PARAM_CALCULO';
export const EXC_PARAM_CALCULO = 'EXC_PARAM_CALCULO';
export const CON_PERFIL = 'CON_PERFIL';
export const ALT_PERFIL = 'ALT_PERFIL';
export const CON_PROGRAMA = 'CON_PROGRAMA';
export const INS_PROGRAMA = 'INS_PROGRAMA';
export const ALT_PROGRAMA = 'ALT_PROGRAMA';
export const EXC_PROGRAMA = 'EXC_PROGRAMA';
export const CON_SIM_LIMITE = 'CON_SIM_LIMITE';
export const CON_TP_ANALISE = 'CON_TP_ANALISE';
export const INS_TP_ANALISE = 'INS_TP_ANALISE';
export const ALT_TP_ANALISE = 'ALT_TP_ANALISE';
export const EXC_TP_ANALISE = 'EXC_TP_ANALISE';
export const CON_COC = 'CON_COC';
export const INS_COC = 'INS_COC';
export const ALT_COC = 'ALT_COC';
export const EXC_COC = 'EXC_COC';
export const CON_LISTAS = 'CON_LISTAS';
export const ALT_LISTAS = 'ALT_LISTAS';
export const CON_PVL = 'CON_PVL';
export const INS_PVL = 'INS_PVL';
export const ALT_PVL = 'ALT_PVL';
export const EXC_PVL = 'EXC_PVL';

// CONSULTAS PÚBLICAS
export const CON_CONSULTA_DOCUMENTO = 'CON_CONSULTA_DOCUMENTO';
export const CON_PUBLICO_NORMATIVO = 'CON_PUBLICO_NORMATIVO';
export const CON_PUBLICO_DEM_JUD = 'CON_PUBLICO_DEM_JUD';
export const CON_PUBLICO_ELEMENTO = 'CON_PUBLICO_ELEMENTO';
export const CON_PUBLICO_ITEM_VERIFICACAO = 'CON_PUBLICO_ITEM_VERIFICACAO'; 

