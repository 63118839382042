import http from 'utils/HttpCommon';
import GenericHttpService from './GenericHttpService';
import { buildUrlSearchParams } from 'utils/utils';
import { identificadorSiconfiFormatter } from 'utils/formatters';

const endpoint = 'coc';

const emptyObject = {
   id: null,
   dividaTipo:null,
   dividaDestinacao: '',
   devedorInfo: {
    listaDevedorTipo: [],
    listaTipoEnte: [],
    listaSiglaUF: [],
    listaEnteFederativo: []
   },
   credor:{
     listaTipoPj:[],
     listaTipoEnte:[],
     listaSiglaUF: [],
     listaEnteFederativo: []
   }
};

const searchOCByIdSiconfi = async (idSiconfiDivida)=>{
  const searchStr = buildUrlSearchParams({identificadorSiconfi:idSiconfiDivida});
  const response = await GenericHttpService(endpoint).paginatedSearch({searchStr:searchStr,page:0,linesPerPage:1})
  if(response.data.totalElements>0){
    return response.data.content[0];
  }
  else{
      throw new Error(`Identificador Siconfi dívida ${identificadorSiconfiFormatter(idSiconfiDivida) } não localizado`);
  }
}


export default {
  ...GenericHttpService(endpoint),
  getEmptyObject: () => ({...emptyObject}),
  getByIdSiconfi: (idSiconfi) => {
    return http.get(`/${endpoint}/buscar-por-identificador-siconfi/${idSiconfi}`);
  },
  searchOCByIdSiconfi
};