import http from '../utils/HttpCommon';
function GenericHttpService(endpoint) {
  return {
    getAll: () => {
      return http.get(`/${endpoint}/buscar`);
    },

    get: (id) => {
      return http.get(`/${endpoint}/buscar/${id}`);
    },
    
    search: (searchStr) => {
      return http.get(`/${endpoint}/pesquisa?${searchStr}`);
    },

    paginatedSearch: ({searchStr, page, linesPerPage, sort, orderBy, direction}) => {
      /* const sortStr = new URLSearchParams(sort).toString()
      console.log('sortStr', sortStr) */
      const query = [
        `/${endpoint}`,
        `/pesquisa-paginada`,
        `?${searchStr}`,
        `&page=${page - 1}`,
        `&size=${linesPerPage}`,
	       sort ? `&${sort}` : ''
        /*orderBy ? `&sort=${orderBy},${direction}` : ''*/
      ].join('');
      return http.get(query);
    },

    exportSearch: ({searchStr, sort, orderBy, direction}) => {
      const query = [
        `/${endpoint}`,
        `/exportar-consulta`,
        `?${searchStr}`,
	       sort ? `&${sort}` : ''
        /*orderBy ? `&sort=${orderBy},${direction}` : ''*/
      ].join('');

      return http.get(query, {
        headers: {
          'Content-Type': 'application/octet-stream; application/zip',
        },
        responseType: 'blob'
      });
    },
    
    create: (data) => {
      return http.post(`/${endpoint}/criar`, data);
    },
    
    update: (id, data) => {
      return http.put(`/${endpoint}/atualizar/${id}`, data);
    },
    
    remove: (id) => {
      return http.delete(`/${endpoint}/remover/${id}`);
    },
    
    removeAll: () => {
      return http.delete(`/${endpoint}/remover`);
    }
  }
}

export default GenericHttpService;
