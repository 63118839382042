import GenericHttpService from './GenericHttpService';
import http from '../utils/HttpCommon';

const emptyObject = {
  nome: '',
  tipo: null,
  listaTpDocumento: [],
  conteudo: '',
  documento_identificadorSiconfi: '',
  //TODO: REMOVER METADADOS
  metadados: {}
}
const endpoint = 'consulta-documento';

const get = (id) => {
  return http.get(`/${endpoint}/buscar/${id}`);
}

export default {
  ...GenericHttpService(endpoint),
  get, 
  getEmptyObject: () => ({...emptyObject})
};